import React from "react"
import NetstorsysSidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import DownloadBox from "./DownloadBox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const Overview = () => {

  return (
    <>
      {/* Erster Abschnitt mit Menü */}
      <section className="services-details-area ptb-100" style={{ paddingBottom: "80px" }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>

              {/* Titelbild */}
              <div className="services-details-image">
                <StaticImage
                  src="../../../assets/images/products/netstorsys/uebersicht/uebersicht_header.png"
                  alt="NETSTORSYS Lagerlogistik und Supply Chain Management Software"
                  placeholder="blurred"
                  width={900}
                />
              </div>

              {/* Erster Text Block mit Titel */}
              <span className="products-sub-title" data-nosnippet="true">Lagerverwaltung</span>
              <h1 className={"pt-3"}>NETSTORSYS - Warehousing der neuen Generation</h1>
              <p>
                Perfekt lagern, kommissionieren, nahtlos versenden, Lagerkosten senken und Kundenzufriedenheit
                erhöhen:
                Das Warehouse Management System NETSTORSYS verändert Ihre Lagerlogistik und richtet sie auf die
                Zukunft aus.
              </p>
              <p>
                Unser WMS erbt die Erfahrung aus der Entwicklung mehrerer individueller, maßgeschneiderter
                Logistiksysteme,
                mit denen wir bei LOGENTIS schon vor mehr als 20 Jahren Logistiksoftware neu gedacht haben.
              </p>

              <h2 style={{ marginTop: "20px", marginBottom: "12px" }}>Ein WMS für Logistik, Handel und
                Hersteller</h2>
              <p>
                Gestartet als Software für E-Commerce-Logistik, ist NETSTORSYS heute ein mächtiges und
                vielseitiges Lagerverwaltungssystem für die Anforderungen sowohl von E-Commerce, Produktion als auch
                Logistikdienstleistern. Wie seine Vorgänger entwickeln wir unser LVS weiter und optimieren es
                kontinuierlich.
              </p>
              <p>
                Darin stecken geballte IT- und Intralogistik-Expertise, Überzeugung und unsere Begeisterung für
                moderne
                Logistiksoftware. Unsere Philosophie: Wir setzen konsequent modernste &nbsp;
                <Link to={"/technologie"}>Technologien</Link> ein, damit unsere
                Kunden den größtmöglichen Nutzen haben und optimal für die Zukunft aufgestellt sind. Die
                browserbasierte
                Software erleichtert und beschleunigt das Management komplexer, zeitgemäßer Logistik auf allen
                Endgeräten. &nbsp;
                <Link to={"/on-premises/"}>On-Premises</Link> oder als Anwendung in der Cloud – Sie entscheiden.
                NETSTORSYS kann beides.
              </p>
            </Col>

            <Col lg={4} md={12}>
              <NetstorsysSidebar />
              {<Link
                to="/kontakt/"
                activeClassName="active"
                className="btn btn-primary btn-lg"
                style={{ marginLeft: "2em", marginTop: "1em" }}
              >
                <FontAwesomeIcon icon={faArrowRight} size={"sm"} alt={"Jetzt beraten lassen"}
                                 style={{ marginRight: "8px" }} /> Jetzt beraten lassen

              </Link>}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Feature Highlight 1 */}
      <section className={"services-area netstorsys-fh bg-f1f8fb"} id="konfiguration">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Bildet jedes Lager ab</span>
          <h2>{"Umfassend konfigurierbar, unschlagbar flexibel"}</h2>
          <div className="configurations-section">
            <ul className={"fa-ul"}>
              <Row style={{ paddingLeft: 0 }}>
                <Col xs={12} lg={6}>
                  <Link to="/dynamische-attribute">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Dynamische Attribute</span>
                      <div>Passen Sie jederzeit Eigenschaften verwalteter Aufträge, Artikel oder Lagerplätzen während
                        der gesamten Lagerprozesse an, lassen Sie Picklisten in Echtzeit durch neue Bestelleingänge
                        erweitern.
                        Priorisieren Sie Aufträge und ordnen Sie Artikel dynamisch zu.
                      </div>
                    </li>
                  </Link>
                  <Link to="/mandanten-und-domaenen">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Mandanten und Domänen</span>
                      Lager, Lagerbereiche oder Standorte sind nach Mandanten und gemeinsamen Domänen strukturiert.
                      Sie lassen sich flexibel nach Mitarbeiter und Kunden trennen oder gemeinsam nutzen.
                    </li>
                  </Link>
                  <Link to={"/einlagerstrategie/"}>
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Einlagerungsstrategie</span>
                      Egal ob LIFO, FIFO, systematische oder dynamische Lagerhaltung – lagern Sie Waren anhand
                      beliebiger
                      Reservierungsstrategien
                      laufwegsoptimiert ein.
                    </li>
                  </Link>
                  <Link to={"/batch/"}>
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Batchsystem</span>
                      Einfach schneller fertig sein – NETSTORSYS fasst Kundenaufträge, Ein-, Um und Auslagerungen sowie
                      Inventuren zu intelligenten Touren zusammen, die Laufwege, Zeit und Personalaufwand sparen
                    </li>
                  </Link>
                </Col>
                <Col xs={12} lg={{ span: 6 }}>
                  <Link
                    to={"/reservierungsstrategie/"}>
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Reservierungsstrategien </span>
                      Artikel können z.B. nach Mindesthaltbarkeit, Liefertermin, Charge, Seriennummer oder optimaler
                      Zugänglichkeit für Aufträge reserviert werden – je nach Art der Güter, der Lagerorganisation, den
                      Kundenanforderungen und den Unternehmenszielen schlägt das LVS die besten Strategien vor und
                      automatisiert sie.
                    </li>
                  </Link>
                  <Link
                    to={"/wie-kann-ich-kommissionierzeit-optimieren/"}>
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Laufwege</span>
                      Jeder Gang macht schlank, kostet im Lager aber Zeit und Ressourcen. Mit digitaler Lagerverwaltung
                      erreichen
                      Sie weniger und kürzere Laufwege bei Kommissionierung, Materialfluss und Inventur.
                    </li>
                  </Link>
                  <Link to="/deep-dive">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Rechte-Management </span>
                      Zugriffsrechte und Rollen verwalten Sie so, dass die Lagerverwaltung zu Ihren Prozessen passt.
                      Legen
                      Sie
                      Bedienoberflächen, Abläufe und Arbeitsbereiche für jeden Mitarbeiter individuell fest.
                    </li>
                  </Link>
                  <Link to="/task-handling-zustands-handling/">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Task-Handling & Zustands-Handling </span>
                      Das LVS generiert Aufgaben anhand von Aufträgen, Lagerbeständen und anderen Parametern, weist sie
                      Mita
                      rbeitern
                      zu und verfolgt den Fortschritt. Es speichert den aktuellen Zustand der Objekte, um Prozesse zu
                      steuern und zu überwachen.
                      Optimieren Sie Ressourcen, Lagerbestände, Artikelstruktur und Prozesse datengetrieben.
                    </li>
                  </Link>
                </Col>
              </Row>
            </ul>
          </div>
          <div style={{ display: "flex", justifyContent: "end", marginTop: "3em", marginRight: "1em" }}>
            <Link to={"/deep-dive/"}>
              <button type="button" className="btn btn-outline-primary">
                <i className="bx bx-chevron-right" />
                Mehr Deep-Dives
              </button>
            </Link>
          </div>
        </Container>
      </section>

      {/* Feature Highlight 2 */}
      <section className="services-area netstorsys-fh bg-fffbf5">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Einfach besser lagern</span>
          <h2>{"Vielseitig und hochaktuell"}</h2>
          <Row>
            <Col lg={6} style={{ paddingLeft: "3em" }}>
              <p>
                Ein konfigurierbares Task-Management und detaillierte Zustandsmeldungen richten das WMS an bestehenden
                Lagerprozessen aus und helfen, diese zu durchleuchten und zu optimieren. Unsere Software unterstützt
                individuelle Sortimentsstrukturen, Lager- und Kommissionierstrategien, optimiert Laufwege,
                Wareneingang
                und Warenausgang, um den Durchsatz zu erhöhen und Durchlaufzeiten zu senken.
              </p>
              <p>
                Nutzen Sie ein einfach zu bedienendes, flexibles, skalierbares System, das komplexe Intralogistik
                vereinfacht, beschleunigt, Standards und individuelle Module kombiniert und sich nahtlos in Ihre
                Systemlandschaft integrieren lässt. Es macht Ihre Lagerlogistik effektiver und wirtschaftlicher denn
                je.
              </p>
              <p>
                NETSTORSYS lebt durch die Vielfalt unserer Kunden: Ihr wertvolles Feedback fließt in regelmäßige
                Releases
                und die hohe Qualität ein. Gemeinsam mit Ihnen machen wir unser WMS stetig besser, erweitern es und
                halten es auf dem neuesten Stand. Zählen Sie auf umfassenden Support.
              </p>
            </Col>
            <Col sm={12} lg={{ offset: 1, span: 5 }} style={{ display: "flex", justifyContent: "center" }}>
              <StaticImage
                src="../../../assets/images/products/netstorsys/wm-warehousetimeline.svg"
                alt="Handlingunits Screenshot"
                placeholder="blurred"
                width={500}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Feature Highlight 3 */}
      <section className="services-area netstorsys-fh bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Pünktlich liefern</span>
          <h2>{"Integrierter Versand"}</h2>
          <Row>
            <Col sm={12} lg={6} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
              <StaticImage
                src="../../../assets/images/products/netstorsys/integrierter_versand.svg"
                alt="Artikelmanagement Screenshot"
                placeholder="blurred"
                width={400}
              />
            </Col>
            <Col style={{
              display: "flex",
              alignItems: "start",
              paddingLeft: "3em",
              flexDirection: "column",
              justifyContent: "center"
            }} lg={{ span: 6, offset: 0 }}>
              <p>Denken Sie erfolgreiche Lagerverwaltung und reibungslosen Versand zusammen: Durch nahtlose
                Integration
                unseres etablierten Multicarrier-Versandsystems NETVERSYS versenden Sie Ihre Waren direkt aus
                NETSTORSYS,
                ohne Systeme wechseln zu müssen. Das spart Zeit und minimiert mögliche Bedienfehler. So managen Sie
                mit
                NETSTORSYS nicht nur erfolgreich Ihr Lager, Sie profitieren gleichzeitig auch von allen Vorteilen und
                Stärken eines ausgereiften, modernen Versandsystems.</p>
              <Link
                to="/versandsoftware/"
                activeClassName="active"
                className="btn btn-primary btn-lg"
                style={{ marginTop: "1em" }}
              >
                <FontAwesomeIcon icon={faArrowRight} size={"sm"} alt={"Multicarrier-Versand"}
                                 style={{ marginRight: "8px" }} /> Multicarrier-Versand

              </Link>
            </Col>

          </Row>
        </Container>
      </section>

      <section className="services-details-area d-lg-none">
        <div className="services-details-info p-5">
          <DownloadBox />
        </div>
      </section>
    </>
  )
}

export default Overview
